import request from '@/utils/request'
const http = '/admin'
// 售后列表list
export function refundQueryList(data) {
  return request({
    url: http + '/refundQuery/queryPage',
    method: 'POST',
    data
  })
}
// 售后详 情
export function refundQueryDetail(data) {
  return request({
    url: http + '/refundQuery/queryDetail',
    method: 'POST',
    data
  })
}
// 退款
export function accountRefund(data) {
  return request({
    url: http + '/refundManage/accountRefund',
    method: 'POST',
    data
  })
}

export function pageQueryUpdateAddressList(data) {
  return request({
    url: http + '/refundAddress/pageQueryUpdateAddressList',
    method: 'POST',
    data
  })
}

export function pageQueryUpdateAddressRecordList(data) {
  return request({
    url: http + '/refundAddress/pageQueryUpdateAddressRecordList',
    method: 'POST',
    data
  })
}

export function listRefund(data) {
  return request({
    url: http + '/refundInfo/listRefund',
    method: 'POST',
    data
  })
}

export function detailRefund(data) {
  return request({
    url: http + '/refundInfo/detailRefund',
    method: 'POST',
    data
  })
}

export function approveRefundService(data) {
  return request({
    // url: http + '/refundInfo/approveRefundService',
    url: http + '/refundInfo/approvePopRefundService',
    method: 'POST',
    data
  })
}

export function refundServiceInfoStatusEnum(data) {
  return request({
    url: http + '/enumList/refundServiceInfoStatusEnum',
    method: 'POST',
    data
  })
}

export function refundServiceApproveStatusEnum(data) {
  return request({
    url: http + '/enumList/refundServiceApproveStatusEnum',
    method: 'POST',
    data
  })
}

export function orderSendStatusEnum(data) {
  return request({
    url: http + '/enumList/orderSendStatusEnum',
    method: 'POST',
    data
  })
}

export function refundServiceInfoTypeWebEnum(data) {
  return request({
    url: http + '/enumList/refundServiceInfoTypeWebEnum',
    method: 'POST',
    data
  })
}

export function listRefundProduct(data) {
  return request({
    url: http + '/refundInfo/listRefundProduct',
    method: 'POST',
    data
  })
}

export function queryRefundReasonList(data) {
  return request({
    url: '/order/refundInfo/queryRefundReasonList',
    method: 'POST',
    data
  })
}

export function addRefundServiceInfo(data) {
  return request({
    url: http + '/refundInfo/addRefundServiceInfo',
    method: 'POST',
    data
  })
}

export function getPopRefundOrderList(data) {
  return request({
    url: http + '/refundInfo/getPopRefundOrderList',
    method: 'POST',
    data
  })
}

export function getPopRefundOrderDetail(data) {
  return request({
    url: http + '/refundInfo/getPopRefundOrderDetail',
    method: 'POST',
    data
  })
}

export function sellerRefundServiceInfoStatusEnum(data) {
  return request({
    url: http + '/enumList/sellerRefundServiceInfoStatusEnum',
    method: 'POST',
    data
  })
}

// 换货

// 获取换货商品
export function listReplaceProduct(data) {
  return request({
    url: http + '/refundInfo/listReplaceProduct',
    method: 'POST',
    data
  })
}

// 获取换货商品数据和相关促销
export function getExchangeCommodityPromotion(data) {
  return request({
    url: http + '/refundInfo/getExchangeCommodityPromotion',
    method: 'POST',
    data
  })
}

// 换货提交
export function addReplace(data) {
  return request({
    url: http + '/refundInfo/addReplace',
    method: 'POST',
    data
  })
}
