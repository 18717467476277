<!--
 * @Author: goodLiang
 * @Date: 2023-12-04 17:42:22
 * @LastEditors: goodLiang
 * @LastEditTime: 2024-06-27 09:49:06
 * @FilePath: /business-cms/src/views/manage/service/list.vue
-->
<template>
  <div class="container">
    <CustomCard>
      <FoldPanel>
        <el-form label-position="left" label-width="130px">
          <el-row :gutter="30">
            <GridItem>
              <el-form-item>
                <template #label>
                  <el-select v-model="codeData" placeholder="请选择">
                    <el-option label="订单编号" :value="'order_id'"></el-option>
                    <el-option
                      label="配送单号"
                      :value="'refund_service_id'"
                    ></el-option>
                    <el-option label="SKU编码" :value="'sku'"></el-option>
                  </el-select>
                </template>

                <el-input
                  placeholder="请输入"
                  clearable
                  v-model="formData[codeData]"
                ></el-input>
              </el-form-item>
            </GridItem>
            <GridItem>
              <el-form-item label="售后类型">
                <el-select
                  clearable
                  v-model="formData.refund_type"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in emunList.refundServiceInfoTypeWeb"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </GridItem>
            <GridItem>
              <el-form-item label="售后状态">
                <el-select
                  clearable
                  v-model="formData.refund_status"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in emunList.refundServiceInfo"
                    :key="item.code"
                    :label="item.webName"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </GridItem>
            <GridItem>
              <el-form-item label="收货人手机号">
                <el-input
                  placeholder="请输入"
                  clearable
                  v-model="formData.address_mobile"
                ></el-input>
              </el-form-item>
            </GridItem>
            <GridItem>
              <el-form-item label="发货状态">
                <el-select
                  clearable
                  v-model="formData.send_status"
                  placeholder="请选择"
                >
                  <el-option label="全部" :value="''"></el-option>
                  <el-option label="未发货" :value="0"></el-option>
                  <el-option label="部分发货" :value="1"></el-option>
                  <el-option label="全部发货" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </GridItem>
            <GridItem>
              <el-form-item label="审核状态">
                <el-select
                  clearable
                  v-model="formData.approve_status"
                  placeholder="请选择"
                >
                  <el-option label="全部" :value="''"></el-option>
                  <el-option label="待审核" :value="0"></el-option>
                  <el-option label="审核驳回" :value="1"></el-option>
                  <el-option label="审核通过" :value="2"></el-option>
                  <el-option label="审核中" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </GridItem>
            <GridItem :lg="16">
              <el-form-item label="申请时间">
                <el-date-picker
                  v-model="time1"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  align="right"
                >
                </el-date-picker>
              </el-form-item>
            </GridItem>
            <GridItem :lg="16">
              <el-form-item label="退款时间">
                <el-date-picker
                  v-model="time2"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  align="right"
                >
                </el-date-picker>
              </el-form-item>
            </GridItem>
            <GridItem>
              <el-form-item label="退款金额">
                <div class="i-flex i-col-center">
                  <div class="i-flex-1">
                    <el-input-number
                      v-model="startAmount"
                      class="i-width-full"
                    ></el-input-number>
                  </div>
                  <span class="i-m-l-10 i-m-r-10"> - </span>
                  <div class="i-flex-1">
                    <el-input-number
                      v-model="endAmount"
                      class="i-width-full"
                    ></el-input-number>
                  </div>
                </div>
              </el-form-item>
            </GridItem>
          </el-row>
        </el-form>
        <template #operation>
          <el-button type="primary" @click="lookUp">查询</el-button>
          <el-button @click="resetFormData">重置</el-button>
        </template>
      </FoldPanel>
      <el-divider></el-divider>
      <div v-if="tabelData.list.length">
        <div class="table-header">
          <div class="column i-text-left" style="width: 30%">订单信息</div>
          <div class="column" style="width: 14%">金额(元)</div>
          <div class="column" style="width: 13%">发货状态</div>
          <div class="column" style="width: 13%">售后类型</div>
          <div class="column" style="width: 10%">售后状态</div>
          <div class="column" style="width: 10%">售后原因</div>
          <div class="column" style="width: 10%">操作</div>
        </div>
        <div
          class="goods-info"
          v-for="item in tabelData.list"
          :key="item.order_id"
        >
          <div class="header">
            <div class="header-left">
              <div
                class="mr20"
                @click="
                  toDetail(item.refund_service_id, item.has_approve_button)
                "
              >
                售后单号：<span class="clr cp">{{
                  item.refund_service_id
                }}</span>
              </div>
              <div class="mr20" @click="copy(item.order_id)">
                订单号：<span>{{ item.order_id }}</span>
                <i
                  style="margin-left: 5px"
                  class="el-icon-document-copy cp"
                ></i>
              </div>
              <div class="mr20">申请时间：{{ item.create_time }}</div>
            </div>
          </div>
          <div class="detail">
            <div class="column goods" style="width: 30%">
              <img :src="item.image" alt="" />
              <div class="info">
                <div class="title">{{ item.sku_name }}</div>
                <div class="code">商品编码：{{ item.sku }}</div>
              </div>
            </div>
            <div class="column" style="width: 14%">
              <div>
                <p>实收：¥ {{ item.pay_amount / 100 }}</p>
                <p v-if="item.refund_type == 2 && item.refund_flag == 2">
                  再支付： +¥ {{ item.refund_amount / 100 }}
                </p>
                <p v-else-if="item.refund_type == 2 && item.refund_flag == 3">
                  退款： -
                </p>
                <p v-else>退款： +¥ {{ item.refund_amount / 100 }}</p>
              </div>
            </div>
            <div class="column" style="width: 13%">
              {{ item.send_status_name }}
            </div>
            <div class="column" style="width: 13%">
              {{ item.refund_type_name }}
            </div>
            <div class="column" style="width: 10%">{{ item.refund_name }}</div>
            <div class="column" style="width: 10%">
              {{ item.refund_reason }}
            </div>
            <div class="column" style="width: 10%">
              <el-link
                type="primary"
                v-if="item.has_approve_button === 1"
                @click="sendAgreePop(item)"
                >同意退款</el-link
              >
              <el-link type="primary" @click="toDetail(item.refund_service_id)"
                >查看详情</el-link
              >
            </div>
          </div>
        </div>
      </div>
      <template #bottomOperation v-if="tabelData.list.length">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          v-model:current-page="formData.page_index"
          :page-sizes="[10, 20, 50, 100]"
          v-model:page-size="formData.page_size"
          layout="total, prev, pager, next, sizes"
          :total="tabelData.total"
        />
      </template>
      <div class="goods-info no-data" v-if="!tabelData.list.length">
        暂无数据
      </div>
    </CustomCard>

    <el-dialog title="同意退款" v-model="showAgreePop" width="55%">
      <el-form label-width="110px">
        <el-form-item required label="退款金额">
          <div>
            <div style="color: #9e2714">¥ {{ afterSaleInfo.amount / 100 }}</div>
            <div style="color: gray; font-size: 14px">
              退款金额将原路退回买家账户
            </div>
          </div>
        </el-form-item>
        <el-form-item label="留言">
          <el-input
            style="width: 400px"
            type="textarea"
            v-model="afterSaleInfo.reason"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="agreeAfterSale">确认退款</el-button>
          <el-button @click="showAgreePop = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import {
  sellerRefundServiceInfoStatusEnum,
  refundServiceInfoTypeWebEnum,
  getPopRefundOrderList,
  approveRefundService
} from '@/api/aftersale'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { useDoubleConfirm } from '@/hooks/useDoubleConfirm'
import { getTime } from '@/utils/util.js'

const router = useRouter()

const emunList = reactive({
  refundServiceInfo: [],
  refundServiceInfoTypeWeb: []
})

onMounted(() => {
  getList()

  const promises = [
    sellerRefundServiceInfoStatusEnum(),
    refundServiceInfoTypeWebEnum()
  ]
  Promise.all(promises).then((res) => {
    // 售后状态
    emunList.refundServiceInfo = res[0]
    // 售后类型
    emunList.refundServiceInfoTypeWeb = res[1]
  })
})

const codeData = ref('order_id')
const formData = reactive({
  order_id: null,
  refund_service_id: null,
  sku: null,
  refund_type: null,
  refund_status: null,
  address_mobile: null,
  send_status: null,
  approve_status: null,
  reach_time_start: null,
  reach_time_end: null,
  create_time_start: null,
  create_time_end: null,
  refund_amount_start: null,
  refund_amount_end: null,
  page_index: 1,
  page_size: 10
})
const startAmount = ref(undefined)
const endAmount = ref(undefined)
const time1 = ref(undefined)
const time2 = ref(undefined)

const lookUp = () => {
  formData.page_index = 1
  formData.refund_amount_start = startAmount.value * 100
  formData.refund_amount_end = endAmount.value * 100
  if (Object.prototype.toString.call(time1.value) === '[object Array]') {
    formData.create_time_start = getTime(time1.value[0])
    formData.create_time_end = getTime(time1.value[1])
  }
  if (Object.prototype.toString.call(time2.value) === '[object Array]') {
    formData.reach_time_start = getTime(time2.value[0])
    formData.reach_time_end = getTime(time2.value[1])
  }
  getList()
}
const resetFormData = () => {
  Object.keys(formData).map((key) => (formData[key] = null))
  startAmount.value = endAmount.value = undefined
  time1.value = time2.value = undefined
  getList()
}

const tabelData = reactive({
  list: [],
  total: 0
})
const getList = () => {
  getPopRefundOrderList({
    ...formData
  }).then((res) => {
    const { data, page_count } = res
    tabelData.list = data || []
    tabelData.total = page_count || 0
  })
}
const sizeChange = (page) => {
  formData.page_size = page
  getList()
}
const currentChange = (page) => {
  formData.page_index = page
  getList()
}

const copy = (data) => {
  const url = data
  const oInput = document.createElement('input')
  oInput.value = url
  document.body.appendChild(oInput)
  oInput.select() // 选择对象;
  document.execCommand('Copy') // 执行浏览器复制命令
  ElMessage({
    message: '复制成功',
    type: 'success'
  })
  oInput.remove()
}
const toDetail = (refundServiceId) => {
  router.push(`/manage/service/detail/${refundServiceId}`)
}

const showAgreePop = ref(false)
const afterSaleInfo = reactive({
  refund_service_id: null,
  order_id: null,
  amount: 0,
  reason: ''
})
const sendAgreePop = (data) => {
  const { pay_amount, refund_service_id, order_id } = data
  afterSaleInfo.amount = pay_amount
  afterSaleInfo.order_id = order_id
  afterSaleInfo.refund_service_id = refund_service_id
  showAgreePop.value = true
}
const agreeAfterSale = async () => {
  if (!(await useDoubleConfirm('确认进行此次操作嘛？', '提示', ''))) return

  approveRefundService({
    order_id: afterSaleInfo.order_id,
    refund_service_id: afterSaleInfo.refund_service_id,
    approve_status: 2,
    fail_reason: afterSaleInfo.reason
  }).then((res) => {
    showAgreePop.value = false
    ElMessage({
      type: 'success',
      message: '操作成功！'
    })
  })
}
</script>

<style lang="scss" scoped>
.container {
  .tab-box {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding: 4px;
    background: #f3f5f7;
    border-radius: 4px;
    margin-bottom: 20px;

    .tab {
      cursor: pointer;
      padding: 8px 24px;
      border-radius: 2px;
    }

    .active {
      color: #0071db;
      background: #ffffff;
    }
  }

  .table-header {
    width: 100%;
    background: #f3f5f7;
    border-radius: 2px;
    border: 1px solid #dce6f0;
    margin-bottom: 20px;
    display: flex;

    .column {
      box-sizing: border-box;
      border-right: 1px solid #dce6f0;
      height: 40px;
      text-align: center;
      line-height: 40px;
      color: #484d52;
      padding: 0 20px;
    }

    .column:last-child {
      border-right: 1px solid transparent;
    }
  }

  .goods-info {
    width: 100%;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #dce6f0;
    margin-bottom: 30px;

    .header {
      box-sizing: border-box;
      padding: 0 20px;
      height: 48px;
      background: #f7fafc;
      border-radius: 2px;
      border-bottom: 1px solid #dce6f0;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-left {
        display: flex;
      }
    }

    .detail {
      display: flex;

      .column {
        height: 105px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #dce6f0;
        font-size: 14px;
        padding: 0 20px;
      }

      .column:last-child {
        border-right: 1px solid transparent;
      }
      .column.goods {
        justify-content: flex-start;
      }
      .goods {
        img {
          width: 64px;
          height: 64px;
          background: #c4c4c4;
          margin-right: 8px;
        }

        .info {
          width: 58%;
          height: 64px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            width: 100%;

            font-size: 14px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #262626;
          }

          .code {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #484d52;
          }
        }
      }
    }
  }

  .mr20 {
    margin-right: 20px;
  }

  .no-data {
    height: 153px;
    color: #262626;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.export-style {
  padding: 30px;
  background: #f7fafc;

  p {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #001426;
    margin-bottom: 30px;
  }
}
</style>
