/*
 * @Author: goodLiang
 * @Date: 2023-12-05 11:30:46
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-05 13:24:59
 * @FilePath: /vue3-template/src/hooks/useDoubleConfirm.js
 */
import { ElMessageBox } from 'element-plus'

export const useDoubleConfirm = (
  ctx,
  title,
  type = 'warning',
  confirmButtonText = '确定',
  cancelButtonText = '取消'
) => {
  return new Promise(resolve => {
    ElMessageBox.confirm(ctx, title, {
      confirmButtonText,
      cancelButtonText,
      type
    })
      .then(() => resolve(true))
      .catch(() => resolve(false))
  })
}
